<template>
  <div class="mark" v-if="!click">
    <van-loading type="spinner" color="white"  vertical>加载中...</van-loading>
  </div>
</template>

<script>
  import { Loading } from 'vant';
  import Vue from 'vue';
  Vue.use(Loading);
  export default {
    name: 'loading',
    props: {
      click: Boolean
    }
  }
</script>

<style scoped>
  .mark {
    width:120px;
    height: 120px;
    background: rgba(0,0,0,.7);
    position: fixed;
    z-index: 20;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top:40%;
    left: 50%;
    transform: translate(-50%);
    color:white;
  }
</style>
