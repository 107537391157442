// 本地存储session
export function setStorage(key, data) {
    return localStorage.setItem(key, data)
}
// 本地获取session
export function getStorage(key) {
    return localStorage.getItem(key)
}

//时间戳格式化
export function add0(m) { return m < 10 ? '0' + m : m }
export function timeFormat(time) {
    let date = new Date(time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    var d = date.getDate();
    var h = date.getHours();
    var mm = date.getMinutes();
    var s = date.getSeconds();
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}
//年月日格式
export function timeYear(time) {
  let date = new Date(time);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  return y + '年' + add0(m) + '月' + add0(d) + '日';
}
