<template>
  <div class="mission-dialog">
    <van-overlay :show="missionVisible">
      <div class="wrapper-invite" v-if="dialogParams.missionType == 1">
        <div class="content">
          <p class="difference-price">
            仅差
            <span>{{ dialogParams.priceDifference }}元</span>
            可领
          </p>
        </div>
        <div class="invite">
          <div class="progress">
            <van-progress :percentage="dialogParams.percent ? (dialogParams.percent).toFixed(2) : 0" stroke-width="8" color="#FDDD6D"
                          track-color="#FFFFFF"/>
            <div class="progress-tips">
              <p>即将完成</p>
            </div>
          </div>
          <div class="invite-text">
            <p>累计到100元即可提现到微信零钱</p>
          </div>
        </div>
        <div class="share-button">
          <van-button @click="operate(5)">立即邀请好友</van-button>
          <p class="share-text" @click="handleJumpWithdraw">查看提现攻略</p>
        </div>
        <div class="close">
          <img src="https://cdn.cailu88.com/jingxuanshi/close%402x.png" @click="handleCloseOverlay"/>
        </div>
      </div>
      <div class="wrapper" v-else>
        <div class="rules-image">
          <img v-if="dialogParams.missionType == 3" style="width: 236px" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_goods_title.png">
          <img v-if="dialogParams.missionType == 2" style="width: 168px" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_order_title.png">
          <img v-if="dialogParams.missionType == 4" style="width: 236px" src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_friends_title.png">
        </div>
        <div class="box">
          <div class="title">
            <p v-if="dialogParams.missionType == 3">使用鲸选师分享赚功能分享商品给好友下单即可获得任务奖励金</p>
            <p v-if="dialogParams.missionType == 2">使用鲸选师在任意电商平台下单<br/>获得返利即可获得任务奖励金</p>
            <p v-if="dialogParams.missionType == 4">好友使用鲸选师下单<br/>即可获得任务奖励金</p>
          </div>
          <div class="content">
            <img class="money" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_money.png">
            <p class="difference-price">
              仅差
              <span>{{ dialogParams.priceDifference }}元</span>
              可领
            </p>
            <div class="progress" v-if="missionVisible">
              <van-progress :percentage="dialogParams.percent ? (dialogParams.percent).toFixed(2) : 0" stroke-width="8" color="#E02D20"
                            track-color="#FDDD6D"/>
              <div class="progress-tips">
                <p>即将完成</p>
              </div>
            </div>
          </div>
          <div class="share-button">
            <van-button class="go-index" v-if="dialogParams.missionType == 2" @click="operate(1)">去首页逛逛</van-button>
            <van-button class="go-goods" v-if="dialogParams.missionType == 2" @click="operate(2)">查看福利商品</van-button>
            <van-button v-if="dialogParams.missionType == 3" @click="operate(3)">立即分享福利商品</van-button>
            <van-button v-if="dialogParams.missionType == 4" @click="operate(4)">推荐福利</van-button>
            <p class="share-text">更多领现金方式，可查看<span class="withdraw-strategy" @click="handleJumpWithdraw">提现攻略</span></p>
          </div>
        </div>
        <div class="close">
          <img src="https://cdn.cailu88.com/jingxuanshi/close%402x.png" @click="handleCloseOverlay"/>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Vue from 'vue'
import { Progress, Button, Overlay } from 'vant'
import baseUrl from '@/utils/baseUrl'
const api = require('../../../utils/api').api

Vue.use(Progress)
Vue.use(Button)
Vue.use(Overlay)
export default {
  name: 'missionDialog',
  data () {
    return {
      shareUrl: ''
    }
  },
  props: {
    missionVisible: Boolean,
    dialogParams: Object,
  },
  created () {
  },
  methods: {
    // 关闭遮罩
    handleCloseOverlay () {
      this.$emit('handleClose')
    },
    // 分享
    handleShareGoods () {
    },
    // 查看提现
    handleJumpWithdraw () {
      this.$emit('scrollToStrategy')
    },
    // 按钮
    operate (type) {
      // type: 1去首页 2查看福利商品 3分享福利商品 4推荐福利 5邀请好友
      let event = '',
        param = {}
      if (type === 1) {
        event = 'navIndex';
        this.$h5AppLink(event, param)
      } else if (type === 5) {
        event = 'share'
        param = {
          type: 'link',
          url: `${baseUrl.pageUrl}/packet/wx?taskId=${this.dialogParams.taskId}`,
          imageUrl: 'https://cdn.cailu88.com/jingxuanshi/material/share/hongbao_share.jpg',
          title: '快快帮我助力领现金红包',
          content: '返利APP鲸选师在发现金红包了，快来互相助攻领去啊！'
        }
        this.$h5AppLink(event, param)
      } else {
        this.$emit('scrollToGoods');
      }
    },
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/pull?taskId=${this.dialogParams.taskId}`
      }
      this.$h5AppLink(event, param)
    },
  }
}
</script>

<style lang="less" scoped>
.mission-dialog {
  .van-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
  }

  .wrapper-invite{
    width: 314px;
    height: 380px;
    background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_invite_box.png");
    background-size: cover;
    .difference-price {
      margin-top: -15px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;

      & > span {
        margin: 0 3px;
        color: #FC1330;
      }
    }
    .content{
      margin-top: 120px;
      text-align: center;
    }
    .invite{
      margin-top: 30px;
      text-align: center;
      .invite-text{
        margin-top: 40px;
        & > p {
          font-size: 15px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 21px;
        }
      }
    }

    .progress {
      margin: 0 auto;
      position: relative;
      top: 23px;
      width: 220px;

      .progress-tips {
        position: absolute;
        bottom: 10px;
        right: -25px;
        width: 64px;
        height: 24px;
        text-align: center;
        background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_invite_tips.png") no-repeat;
        background-size: cover;

        & > p {
          font-size: 10px;
          font-weight: 400;
          color: #E81B1F;
          line-height: 20px;
        }
      }
    }

    .share-button {
      text-align: center;
      margin-top: 28px;

      .van-button {
        width: 260px;
        height: 50px;
        background: Linear-gradient(#FFE4B5, #FDDD6D 40%);
        box-shadow: 0 3px 4px 0 rgba(217, 169, 0, 0.5);
        border-radius: 30px;
        border: none;
        font-size: 18px;
        font-weight: 800;
        color: #E02D20;
      }

      .share-text {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 25px;

        .withdraw-strategy {
          color: #FDDD6D;
        }
      }
    }
    .close {
      margin-top: 54px;
      text-align: center;

      & > img {
        width: 32px;
      }
    }
  }
  .wrapper {
    position: relative;

    .rules-image {
      text-align: center;
    }

    .box {
      width: 310px;
      height: 369px;
      background: #E81B1F;
      border-radius: 20px;
      border: 4px solid #FDDD6E;

      .title {
        margin: 16px 26px 0;
        text-align: center;
        font-size: 15px;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 21px;
      }

      .content {
        margin: 18px 16px 28px;
        width: 270px;
        height: 162px;
        text-align: center;
        background: #FFEBD2;
        border-radius: 20px;
        border: 4px solid #FDDD6E;
        .difference-price {
          margin-top: -15px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;

          & > span {
            margin: 0 3px;
            color: #FC1330;
          }
        }

        .money {
          margin-left: -30px;
          width: 168px;
          height: 102px;
        }

        .progress {
          position: relative;
          top: 23px;
          left: 20px;
          width: 220px;

          .progress-tips {
            position: absolute;
            bottom: 10px;
            right: -25px;
            width: 64px;
            height: 24px;
            text-align: center;
            background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_tips.png") no-repeat;
            background-size: cover;

            & > p {
              font-size: 10px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
        }
      }

      .share-button {
        text-align: center;
        margin-top: 28px;

        .van-button {
          width: 260px;
          height: 50px;
          background: Linear-gradient(#FFE4B5, #FDDD6D 40%);
          box-shadow: 0 3px 4px 0 rgba(217, 169, 0, 0.5);
          border-radius: 30px;
          border: none;
          font-size: 18px;
          font-weight: 800;
          color: #E02D20;
        }

        .go-index {
          width: 130px;
        }
        .go-goods {
          width: 150px;
          margin-left: 6px;
        }

        .share-text {
          margin-top: 20px;
          font-size: 13px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 18px;

          .withdraw-strategy {
            color: #FDDD6D;
          }
        }
      }
    }

    .close {
      margin-top: 34px;
      text-align: center;

      & > img {
        width: 32px;
      }
    }
  }
}
</style>
