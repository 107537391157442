<template>
  <div class="pull-new">
    <div class="head">
      <div class="content">
        <p class="difference-price" v-if="packetInfo.status == 1">
          仅差
          <span>{{ priceDifference }}元</span>
          可领
        </p>
        <p class="difference-price" style="margin-left: 10px" v-else>恭喜，获得现金红包</p>
        <img class="money" src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_money.png">
        <div class="progress">
          <van-progress :percentage="percent" stroke-width="8" color="#FDDD6D"
                        track-color="#E02D20"/>
          <div class="progress-tips">
            <p v-if="isWithdrawal">提现成功</p>
            <p v-else-if="packetInfo.status == 1">仅差{{ priceDifference }}%</p>
            <p v-else-if="packetInfo.status == 2">可以提现啦</p>
          </div>
        </div>
      </div>
      <div class="button">
        <van-button v-if="!isWithdrawal" @click="handleWithdrawal">立即提现</van-button>
        <van-button v-else @click="handleJumpWallet" style="font-size: 18px">红包已进入余额，请及时查看</van-button>
        <p v-if="packetInfo.expiredTime">活动有效期至{{ packetInfo.expiredTime }}</p>
      </div>
      <div class="mission">
        <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_mission.png">
        <div class="mission-list">
          <template v-for="(item, index) in missionList">
            <img :src="`https://cdn.cailu88.com/jingxuanshi/packet_mission${item}.png`"
                 @click="handleMissionClick(item)">
          </template>
        </div>
      </div>
      <img class="strategy" @click="handleShowRules"
           src="https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_rules.png" alt="">
    </div>
    <div class="bottom" id="tabList">
      <div class="tab-content">
        <van-tabs v-model="tabName" animated color="#FDDD6D" background="#EC3710" @change="handleChangeTab">
          <van-tab name="cash" title="现金记录" id="cashRecord">
            <template v-for="item in cashRecordList">
              <div class="cash-item" :index="item.sourceUserId">
                <div class="avatar">
                  <img :src="item.sourceUserAvatar || defaultAvatar">
                </div>
                <div class="name-way">
                  <p style="width: 170px;">{{item.sourceUserNick}}</p>
                  <p style="font-size: 13px;font-weight: 400">{{item.content}}</p>
                </div>
                <div class="value">
                  <p>+ {{item.amount}}</p>
                </div>
              </div>
            </template>
          </van-tab>
          <van-tab name="strategy" title="提现攻略">
            <template v-for="item in strategyList">
              <div class="cash-item" :index="item.id">
                <div class="strategy-index">
                  <p>攻略{{item.id}}</p>
                </div>
                <div class="name-way">
                  <p>{{item.name}}</p>
                  <p style="font-size: 12px;font-weight: 400;opacity: 0.5">{{item.desc}}</p>
                </div>
                <div class="value" style="top: 20px;right: 15px">
                  <p v-if="item.isComplete">已完成</p>
                  <van-button v-else @click="handleInvite(item.id)">{{item.buttonText}}</van-button>
                </div>
              </div>
            </template>
          </van-tab>
          <van-tab name="order" title="到账晒单" id="shareTask" v-if="packetInfo.showTask">
            <template v-for="item in taskShareList">
              <div class="cash-item" :index="item.id">
                <div class="avatar">
                  <img :src="item.wxAvatar || defaultAvatar">
                </div>
                <div class="name-way">
                  <p style="width: 170px;">{{item.name}}</p>
                  <p style="font-size: 13px;font-weight: 400">邀请{{item.numInvite}}名好友获得了100元现金</p>
                </div>
                <div class="value">
                  <p>+ 100</p>
                </div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="footer" id="goodsList">
      <div class="title">
        <p>福利送不停</p>
        <p>先拿返利 再领现金</p>
      </div>
      <div class="goods-list">
        <template v-for="item in goodsList">
          <div class="goods-wrap" :index="item.itemId" @click="handleJumpGoodsDetails(item)">
            <img class="goods-image" v-lazy="item.pictUrl">
            <div class="goods-title">
              <p>
                <img :style="{width:item.itemSource == 3 || item.itemSource == 4 ? '35px' : '30px'}" :src="item.logo">
                {{item.itemTitle}}
              </p>
            </div>
            <div class="goods-discounts">
              <div class="goods-coupon" v-if="item.couponValue">
                <span>{{ item.couponValue }}元券</span>
              </div>
              <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
            </div>
            <div class="goods-price-volume">
              <div class="price">¥ {{ item.reservePrice }}</div>
<!--              <div class="volume" v-if="item.volume && !free">-->
<!--                <span style="color: #333">{{ item.volume }}人</span>-->
<!--                <span> 已购</span>-->
<!--              </div>-->
            </div>
            <div class="goods-promotion-volume">
              <div>
                <span class='money-symbol'>¥ </span>
                <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
              </div>
<!--              <div class="volume" v-if="item.volume && free">-->
<!--                <span v-if="item.volume < 10000" style="color: #333">{{ item.volume }}人</span>-->
<!--                <span v-else style="color: #333">{{ parseFloat((item.volume / 10000).toFixed(2)) }}万人</span>-->
<!--                <span> 已购</span>-->
<!--              </div>-->
            </div>
          </div>
        </template>
      </div>
    </div>
    <van-dialog v-model="rulesVisible" lockScroll :showConfirmButton="false" style="background-color: transparent;">
      <div class="wrapper">
        <div class="rules-image">
          <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/pull_rules_title.png">
        </div>
        <div class="box">
          <div class="title" @click="handleContactService">
            <span>如有问题，烦请联系官方客服</span>
            <van-icon style="margin-left: 4px" name="arrow" size="12"/>
          </div>
          <div class="content">
            <p>
              1、用户在活动中可以通过分享好友注册、邀请好友下单、自购下单以及推广商品好友下单的方式获得现金，已经获得的现金可以在活动主会场进行查看。
              <br/>
              2、用户需要在页面规定时间(2021-01-31 23:59:59)内累积现金达到一定金额门槛才可提现，如逾期未达到门槛，所累积的现金均会失效的哦，详情可以点击页面上的提现按钮进行查看。
              <br/>
              3、用户积累的现金金额达到提现门槛之后，会保留可提现状态72小时（保留时间从达到提现门槛时算起），逾期未提现的现金将会失效，请尽快提现。
              <br/>
              4、只有新用户可以帮忙助力，老用户将无法再帮好友助力；新用户点击好友的助力红包链接有机会可以获得现金，获得的现金会积累到自己的现金总额中。
              <br/>
              5、正常情况下现金在用户发起提现后会以微信转账方式发放到用户的微信零钱账户中；若因用户未通过微信账号登录鲸选师、用户微信账户未进行实名认证等用户自身账户原因致使转账失败，鲸选师有权以发放优惠券形式对该用户进行补偿。
              <br/>
              6、如用户以任何不正当手段或舞弊方式参与本活动，一经发现，鲸选师有权对该用户的账号进行异常标记。对于账号异常的用户的奖励发放，鲸选师有权采取发优惠券、不发放奖励、撤销剩余奖励等措施，亦有权收回用户可领取的金额，追讨已发放的金额，并保留追究该用户责任的权利。如因此给用户造成损失，鲸选师不进行任何赔偿或补偿。不正当手段及舞弊行为包括但不限于：使用非法工具分享、下载、安装、注册、登录多个账号及其他不正当手段。
              <br/>
              7、如果用户存在违规行为（包括但不限于洗钱、虚假交易、赌博、恶意套现、作弊、刷信誉），鲸选师将取消用户的活动资格、并有权撤销相关违规交易、收回奖励（包括已消费金额）等利益，同时依照相关规则进行处理。
              <br/>
              8、如因不可抗力或情势变更（包括但不限于重大自然灾害事件、政府主管部门指令停止举办或调整、活动遭受严重网络攻击或系统故障等）导致本活动无法继续举办的，则鲸选师可决定暂停或终止本活动，并可依相关法律法规的规定主张免责。
              <br/>
              9、依据相关法律法规要求，您在此次活动中为平台提供了推广服务，故平台向您支付现金红包等形式的劳务报酬时需履行代扣代缴义务；您参与此次活动就表示同意并知晓以上纳税义务，如相关报酬需依法申报缴纳相应税款，平台将根据国家税务法规要求向税务机关提供必要的税务申报信息（包括您的身份信息、报酬金额等税务机关要求的信息），并由平台依法为您完成相应的纳税申报。
            </p>
          </div>
        </div>
        <div class="close">
          <img src="https://cdn.cailu88.com/jingxuanshi/close%402x.png" @click="handleCloseOverlay"/>
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model="endVisible" lockScroll :showConfirmButton="false" style="background-color: transparent;">
      <div class="wrapper">
        <div class="title-image">
          <img src="https://cdn.cailu88.com/jingxuanshi/pullNew/activity_ending.png">
        </div>
        <div class="end-box">
          <div class="end-content" style="margin-top: 10px">
            <p>年终百元现金福利活动自上线后，得到了很多鲸选师用户的认可，<span>鲸选师平台希望通过这次活动，让更多人了解鲸选师如何为大家省钱，带大家赚钱。</span></p>
            <p>实际推广的过程中，我们发现一些用户使用非人为手段恶意刷拉新人数，这违背了我们活动的初衷，我们有做出努力希望大家能合理享受福利，但是这些恶意用户使用一些不法手段进行恶意诽谤。</p>
            <p style="color: #FF0000">我们将于2021年01月25日24点结束本期红包活动。</p>
            <p>已经开启红包但未完成任务的用户将不可再进行助力开启任务。</p>
            <p>已经完成任务的用户在结束后依旧可以进入活动页面将奖励进行提现。</p>
            <p>活动入口保留最后3天至<span>2021年01月28日24点。</span>给您带来的不便敬请谅解。</p>
          </div>
          <div style="text-align: center">
            <van-button @click="handleCloseOverlay">我知道了</van-button>
          </div>
        </div>
      </div>
    </van-dialog>
    <Loading :click="click"></Loading>
    <MissionDialog ref="mission" :missionVisible="missionVisible" :dialogParams="dialogParams" @scrollToStrategy="scrollToStrategy" @scrollToGoods="scrollToGoods" @handleClose="handleCloseMissionDialog"></MissionDialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Progress, Button, Tab, Tabs, Dialog, Icon, Toast, Lazyload } from 'vant'
import MissionDialog from './components/missionDialog'
import Loading from '../../components/loading'
import {timeFormat} from "../../utils/utils";
import baseUrl from '@/utils/baseUrl'
const api = require('../../utils/api').api

Vue.use(Progress)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Lazyload, {
  lazyComponent: true,
})
let preD = function (e) {
  e.preventDefault()
}
export default {
  name: 'pullNew',
  data () {
    return {
      taskId: '',
      click: true,
      token: '',
      version: '',
      channel: '',
      // 列表默认头像
      defaultAvatar: 'https://cdn.cailu88.com/jingxuanshi/material/icon/avatar_3x.jpg',
      // 页面选择
      redirectCode: '',
      // 任务遮罩
      missionVisible: false,
      dialogParams: {},
      // 规则遮罩
      rulesVisible: false,
      endVisible: false,
      tabName: 'cash',
      // 红包信息
      packetInfo: {},
      // 差价
      priceDifference: 0,
      percent: 0,
      missionList: [1, 2, 3, 4],
      // 是否提现
      isWithdrawal: false,
      totalCount: 0,
      page: 0,
      pageSize: 10,
      shareTotalCount: 0,
      sharePage: 0,
      sharePageSize: 10,
      goodsPage: 0,
      goodsPageSize: 10,
      goodsTotalCount: 0,
      cashRecordList: [],
      taskShareList: [],
      strategyList: [
        { id:1, desc: '邀请新用户助力开红包', name: '邀请好友助力开红包', buttonText: '立即邀请' },
        { id:2, desc: '好友领福利，自己开红包', name: '好友购买0元购福利', buttonText: '立即邀请' },
        { id:3, desc: '好友得返利，自己开红包', name: '好友完成购物返利', buttonText: '立即邀请' },
        { id:4, desc: '购买得返利，还能开红包', name: '自己完成一次购买返', buttonText: '立即购买' },
        { id:5, desc: '分享赚奖励，还能开红包', name: '自己完成一次分享赚', buttonText: '立即分享' },
      ],
      goodsList: []
    }
  },
  components: {
    MissionDialog,
    Loading
  },
  created () {
    this.token = this.$route.query.token
    this.clientType = this.$route.query.clientType
    this.appClient = this.$route.query.appClient
    this.channel = this.$route.query.channel
    this.version = this.$route.query.version
    this.taskId = this.$route.query.taskId
    this.$store.commit('backToChoose', { isBack: true })
    this.handleInit()
  },
  mounted () {
    window.addEventListener('scroll', this.lazyLoading)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyLoading)
    })
  },
  watch: {
    // 监听遮罩显示隐藏滚动条
    // rulesVisible (val) {
    //   if (val) {
    //     document.body.style.overflow = 'hidden'
    //     document.addEventListener('touchmove', preD, {passive: false})
    //   } else {
    //     document.body.style.overflow = '' // 出现滚动条
    //     document.removeEventListener('touchmove', preD, {passive: false})
    //   }
    // }
  },
  methods: {
    // 获取信息
    handleInit () {
      this.$axios(api.taskIndex, {
        params: {
          taskId: this.taskId
        }
      }).then(res => {
        this.packetInfo = res.data.data
        this.strategyList[3].isComplete = this.packetInfo.orderNumSelfBuy > 0 ? true : false
        this.strategyList[4].isComplete = this.packetInfo.orderNumShareBuy > 0 ? true : false
        this.taskId = this.packetInfo.id
        this.packetInfo.expiredTime = this.packetInfo.expiredTime ? timeFormat(this.packetInfo.expiredTime).substr(0,10) : ''
        this.priceDifference = (this.packetInfo.amount - this.packetInfo.amountFin).toFixed(2)
        this.percent = this.packetInfo.amountFin ? (this.packetInfo.amountFin/this.packetInfo.amount).toFixed(2)*100 : 0
        this.isWithdrawal = this.packetInfo.status == 3 ? true : false
        this.handleGetSupportList()
        this.handleGetGoodsList()
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    // 滚动加载
    lazyLoading () {
      // 获取 可视区高度`clientHeight、`滚动高度`scrollTop、`页面高度`scrollHeight
      let timer = null
      let scrollBox = ''
      let scrollTopGoods = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeightGoods = document.documentElement.clientHeight
      let scrollHeightGoods = document.documentElement.scrollHeight
      if (scrollTopGoods + clientHeightGoods + 10 >= scrollHeightGoods) { // 滚动到底部，逻辑代码
        //事件处理
        if (this.goodsList.length < this.goodsTotalCount && this.goodsTotalCount !== 0) {
          this.handleGetGoodsList('up')
        }
      }
      if (this.tabName == 'cash') {
        scrollBox = document.getElementById('cashRecord')
      } else if (this.tabName == 'order') {
        scrollBox = document.getElementById('shareTask')
      }
      let scrollTop = scrollBox.scrollTop
      let clientHeight = scrollBox.clientHeight
      let scrollHeight = scrollBox.scrollHeight
      if (scrollTop + clientHeight + 10 >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        if (this.cashRecordList.length < this.totalCount && this.totalCount !== 0 && this.tabName == 'cash') {
          this.handleGetSupportList('up')
        } else if (this.taskShareList.length < this.shareTotalCount && this.shareTotalCount !== 0 && this.tabName == 'order') {
          this.handleGetTaskShare('up')
        }
      }
    },

    //滚动到提现攻略
    scrollToStrategy () {
      // 滚动到提现攻略
      this.handleCloseMissionDialog();
      document.getElementById('tabList').scrollIntoView();
      this.tabName = 'strategy';
    },

    //滚动到推荐商品
    scrollToGoods () {
      this.handleCloseMissionDialog();
      document.getElementById('goodsList').scrollIntoView();
    },

    // 切换tab
    handleChangeTab () {
      if (this.tabName == 'cash') {
        this.page = 0
        this.handleGetSupportList()
      } else if (this.tabName == 'order') {
        this.sharePage = 0
        this.handleGetTaskShare()
      }
    },
    handleGetSupportList(type) {
      if (!this.click) {
        return false
      }
      this.page++
      let that = this
      this.click = false
      this.$axios.post(api.taskRecord, {
        taskId: this.taskId,
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.cashRecordList = type == 'up' ? [...this.cashRecordList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        that.click = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
        that.click = true
      })
    },
    // 到账晒单
    handleGetTaskShare (type) {
      if (!this.click) {
        return false
      }
      this.sharePage++
      let that = this
      this.click = false
      this.$axios.post(api.taskShare, {
        status: this.packetInfo.status,
        page: this.sharePage,
        pageSize: this.sharePageSize,
      }).then(res => {
        this.taskShareList = type == 'up' ? [...this.taskShareList, ...res.data.data.data] : res.data.data.data
        this.shareTotalCount = res.data.data.totalCount
        that.click = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
        that.click = true
      })
    },

    // 推荐商品
    handleGetGoodsList (type) {
      if (!this.click && type) {
        return false
      }
      this.goodsPage++
      let that = this
      this.click = false
      this.$axios(api.activityDetails, {
        params: {
          page: this.goodsPage,
          pageSize: this.goodsPageSize,
          activityId: '280',
          clientType: 2
        }
      }).then(res => {
        res.data.data.data ? res.data.data.data.map(item => {
          //itemSource 1-tb , 2-jd , 3-pdd , 4-wph , 5-sn | userType: 0-tb 1-tm
          item.logo = `https://cdn.cailu88.com/jingxuanshi/pullNew/goods_logo_${item.itemSource === 3 ? 'pdd' : item.itemSource === 4 ? 'wph' : item.itemSource === 5 ? 'sn' : item.itemSource === 2 ? 'jd' : item.itemSource === 1 && item.userType == 0 ? 'tb' : item.itemSource === 1 && item.userType == 1 ? 'tm' : ''}.png`
          }) : []
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.goodsTotalCount = res.data.data.totalCount
        that.click = true
      }).catch(err => {
        console.info(err)
        that.click = true
      })
    },
    // 规则
    handleShowRules () {
      this.rulesVisible = true
    },
    // 活动结束
    handleShowEnd () {
      this.endVisible = true
    },
    // 任务列表
    handleMissionClick (value) {
      if (this.packetInfo.status == 1) {
        this.endVisible = true
        // this.missionVisible = true
        // this.dialogParams.missionType = value
        // this.dialogParams.priceDifference = this.priceDifference
        // this.dialogParams.percent = this.percent
        // this.dialogParams.taskId = this.taskId
        // this.dialogParams.token = this.token
      } else {
        Toast({
          duration: 500, // 持续展示 toast
          message: '任务已完成'
        })
      }
    },
    handleCloseMissionDialog () {
      this.missionVisible = false
    },
    // 立即提现
    handleWithdrawal () {
      if (!this.token) {
        this.isLogin()
      }
      if (this.packetInfo.status == 1) {
        // this.handleMissionClick(1)
        this.endVisible = true
      } else if (this.packetInfo.status == 2){
        this.$axios(api.taskWithdraw, {
          params: {
            taskId: this.taskId
          }
        }).then(res => {
          this.isWithdrawal = true
          console.log(res)
        }).catch(err => {
          console.info(err)
        })
      }
    },
    // 立即邀请
    handleInvite (value) {
      this.endVisible = true
      if (value == 4 || value == 5) {
        document.getElementById('goodsList').scrollIntoView();
      // } else {
        // this.dialogParams.taskId = this.taskId
        // this.$refs.mission.operate(5)
      }
    },
    // 提现成功跳转钱包页
    handleJumpWallet () {
      let event = 'navWallet',
        param = {
          tab: '0'                       //0-收益列表; 1-提现列表
        }
      this.$h5AppLink(event, param)
    },

    // 跳转商品详情页
    handleJumpGoodsDetails (item) {
      let event = 'openGoodsInfo',
        param = {
          itemSource: item.itemSource,
          itemId: item.itemId,
          sellerId: item.sellerId
        }
        this.$h5AppLink(event, param)
    },
    // 联系客服
    handleContactService () {
      this.$h5AppLink('contactUs', {})
    },
    // 关闭活动规则遮罩
    handleCloseOverlay () {
      this.rulesVisible = false
      this.endVisible = false
    },
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/pull?taskId=${this.taskId}`
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-progress__pivot {
  display: none;
}
/deep/.van-overlay{
  background-color: rgba(0,0,0,0.8);
}
/deep/.van-tabs{
  .van-tabs__wrap{
    border-radius: 10px;
  }
  .van-tab__pane-wrapper{
    margin-top: 8px;
    max-height: 503px;
    overflow-y: scroll;
    .van-tab__pane{
    }
  }
  .van-tab--active{
    .van-tab__text{
      opacity: 1;
    }
  }
  .van-tab__text{
    color: #FDDD6D;
    opacity: 0.7;
  }
}
.pull-new {
  min-height: 100vh;

  .head {
    width: 100%;
    height: 612px;
    position: relative;
    background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/pull_index_bg2.png") no-repeat;
    background-size: cover;

    .content {
      position: absolute;
      top: 100px;
      left: 86px;
      text-align: center;

      .difference-price {
        margin-left: 30px;
        font-size: 20px;
        font-weight: 800;
        color: #333333;
        line-height: 28px;

        & > span {
          margin: 0 3px;
          color: #FC1330;
        }
      }

      .money {
        width: 168px;
        height: 102px;
      }

      .progress {
        position: relative;
        top: 3px;
        left: 12px;
        width: 192px;

        .progress-tips {
          position: absolute;
          bottom: 10px;
          right: -30px;
          padding: 0 3px;
          width: 60px;
          height: 24px;
          text-align: center;
          background: url("https://cdn.cailu88.com/jingxuanshi/pullNew/new_packet_pull_tips.png") no-repeat;
          background-size: cover;

          & > p {
            font-size: 10px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
          }
        }
      }
    }

    .button {
      position: absolute;
      top: 294px;
      text-align: center;
      width: 100%;

      .van-button {
        width: 300px;
        height: 60px;
        font-size: 24px;
        font-weight: 800;
        color: #E02D20;
        line-height: 33px;
        background: Linear-gradient(#FFE4B5, #FDDD6D 40%);
        border-radius: 8px;
        border: none;
      }

      & > p {
        margin-top: 8px;
        font-size: 15px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
      }
    }

    .mission {
      position: absolute;
      top: 440px;
      text-align: center;
      width: 100%;

      img {
        width: 182px;
        height: 42px;
      }

      .mission-list {
        display: flex;
        justify-content: flex-start;
        margin-top: 14px;
        padding: 0 6px;

        img {
          width: 88px;
          height: 100px;
          margin-right: 4px;
        }

        img:last-child {
          margin-right: 0;
        }
      }
    }

    .strategy {
      position: fixed;
      top: 136px;
      right: 0;
      width: 56px;
      height: 36px;
      z-index: 30;
    }
  }
  .bottom{
    background: #FDD7A7;
    padding-bottom: 20px;
    .tab-content{
      margin: 0 auto;
      width: 350px;
      padding-bottom: 20px;
      //max-height: 576px;
      background: #EC3710;
      border-radius: 10px;
      overflow-y: scroll;
      .cash-item{
        position: relative;
        width: 305px;
        height: 44px;
        background: #FF6145;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto 8px auto;
        padding: 16px 15px;
        .avatar{
          img{
            width: 40px;
            height: 40px;
            border-radius: 20px;
          }
        }
        .name-way{
          color: #ffffff;
          font-size: 15px;
          font-weight: 800;
          margin-left: 16px;
          line-height: 21px;
          & > p:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .value{
          position: absolute;
          right: 0;
          top: 25px;
          width: 80px;
          text-align: center;
          color: #FDDD6D;
          .van-button{
            width: 84px;
            height: 36px;
            background: #FFE68E;
            border-radius: 6px;
            color: #F44A2C;
            font-size: 13px;
            font-weight: 800;
            border: none;
          }
        }
        .strategy-index{
          font-size: 15px;
          font-weight: 800;
          color: #FDDD6D;
          line-height: 21px;
        }
      }
    }
  }
  .footer{
    background: #F4F4F4;
    padding: 0 6px;
    .title{
      padding-top: 12px;
      text-align: center;
      & > p:first-child{
        color: #EC3710;
        line-height: 33px;
        font-size: 24px;
        font-weight: 800;
      }
      & > p:last-child{
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }
    .goods-list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .goods-wrap{
        margin-top: 16px;
        width: 178px;
        height: 300px;
        background: #FFFFFF;
        border-radius: 10px;
        & > img{
          width: 178px;
          height: 178px;
          border-radius: 10px;
        }
        .goods-title{
          display: flex;
          margin: 0 10px;
          & > p {
            & > img {
              position: relative;
              top: 2px;
              width: 30px;
              height: 16px
            }
            font-size: 13px;
            font-weight: 400;
            color: #000000;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .goods-discounts {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 20px;
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          /* color: #8b7f08; */
          line-height: 20px;
          margin: 3px 10px 0;

          .goods-coupon,
          .goods-cashback {
            color: #fff;
            background-color: #E44A59;
            border-radius: 2px;
          }

          .goods-cashback {
            padding: 0 4px;
            color: #FF3B3C;
            background: rgba(255, 59, 60, 0.1);
          }

          .goods-coupon {
            padding: 0 5px;
            margin-right: 8px;
            position: relative;
            text-align: center;
            min-width: 54px;
            height: 20px;
            background: url("https://cdn.cailu88.com/jingxuanshi/client_activity_coupon.png");
            background-size: cover;
            /* overflow: hidden; */
          }
        }

        .goods-price-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 12px 10px 0;

          .price {
            color: #999;
            text-decoration: line-through;
          }
        }

        .goods-promotion-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 10px;

          .money-symbol {
            font-size: 12px;
            font-weight: bold;
            color: #e44a59;
            line-height: 18px;
          }

          .goods-current-price {
            height: 30px;
            font-size: 20px;
            font-weight: bold;
            color: #e44a59;
            line-height: 30px;
          }
        }

        .goods-price-volume .price,
        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          height: 17px;
          font-size: 12px;
          line-height: 17px;
        }

        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          color: #999;
        }
      }
    }
  }
  .wrapper{
    position: relative;
    .title-image {
      text-align: center;
      & > img {
        width: 200px;
        margin-bottom: 15px;
      }
    }
    .rules-image{
      text-align: center;
      & > img {
        width: 135px;
      }
    }
    .end-box{
      height: 455px !important;
    }
    .end-box,
    .box{
      width: 310px;
      height: 430px;
      background: #FFEBD2;
      border-radius: 20px;
      border: 4px solid #FDDD6E;
      .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px auto 16px;
        width: 250px;
        height: 30px;
        background: #FF6145;
        border-radius: 15px;
        font-size: 15px;
        font-weight: 800;
        color: #FDDD6D;
        line-height: 30px;
      }
      .content{
        margin: 0 auto;
        width: 272px;
        height: 350px;
        font-size: 15px;
        font-weight: 400;
        overflow-y: scroll;
        color: #333333;
        line-height: 25px;
      }
      .end-content {
        margin: 10px auto 0 auto;
        width: 272px;
        height: 370px;
        overflow-y: scroll;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        span {
          color: #FF0000;
        }
      }
      .van-button {
        margin-top: 10px;
        width: 270px;
        height: 50px;
        background: #FFCE24;
        border-radius: 10px;
        color: #E02D20;
        font-weight: 800;
      }
    }
    .close{
      margin-top: 34px;
      text-align: center;
      & > img{
        width: 32px;
      }
    }
  }
}
</style>
